import classNames from 'classnames';
import React from 'react';
import RegisterForm from '../account/RegisterForm';
import styles from './RegisterInsert.module.scss';

interface RegisterInsertProps {
  description?: string;
  narrow?: boolean;
  source: string;
  text?: string;
}

const RegisterInsert: React.SFC<RegisterInsertProps> = ({
  description,
  narrow,
  source,
  text,
}) => (
  <div className={classNames(styles.registerCard, { [styles.narrow]: narrow })}>
    <div className={styles.content}>
      <div className={styles.text}>
        {text || 'Create your free team today.'}
      </div>
      {description && <div className={styles.description}>{description}</div>}
    </div>
    <div className={styles.form}>
      <RegisterForm source={source} fieldClassName={styles.registerField} />
    </div>
  </div>
);

export default RegisterInsert;
