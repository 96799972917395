import React from 'react';
import ComparisonTable, {
  Comparisons,
} from '../components/alternative-to/ComparisonTable';
import HomeHeader, { HomeHeaderProps } from '../components/home/HomeHeader';
import MarketingLayout from '../components/layout/MarketingLayout';
import PageSection from '../components/ui/PageSection';
import { Competitor } from '../types';

interface ComparisonTemplateProps extends HomeHeaderProps {
  competitor: Competitor;
  comparisons: Comparisons;
  features: React.ReactNode;
  header?: string;
  subheader?: string;
  metaTitle?: string;
  metaDescription?: string;
}

const ComparisonTemplate = ({
  competitor,
  comparisons,
  features,
  metaTitle,
  metaDescription,
  header,
  subheader,
}: ComparisonTemplateProps) => (
  <MarketingLayout
    navbarShowRegister={false}
    navbarBackground="plum"
    metaTitle={metaTitle}
    description={metaDescription}
    noFooterPadding
  >
    <HomeHeader header={header} subheader={subheader} />
    <PageSection id="features">{features}</PageSection>
    <ComparisonTable
      competitor={competitor}
      comparisons={comparisons}
      registerSource="comparison-import"
    />
  </MarketingLayout>
);

export default ComparisonTemplate;
