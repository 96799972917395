import React from 'react';
import checkIcon from '../../images/alternative-to/check.svg';
import xIcon from '../../images/alternative-to/x.svg';
import slabLogo from '../../images/logo.svg';
import { Competitor } from '../../types';
import PageSection from '../ui/PageSection';
import RegisterInsert from '../ui/RegisterInsert';
import Spacing from '../ui/Spacing';
import styles from './ComparisonTable.module.scss';

interface ComparisonValueType {
  supported: boolean;
  remark?: string;
}

interface Comparison {
  title: string;
  description: string;
  slab: ComparisonValueType;
  competitor: ComparisonValueType;
}

export type Comparisons = Comparison[];

interface ComparisonTableProps {
  competitor: Competitor;
  comparisons: Comparisons;
  registerSource: string;
}

interface ComparisonValueProps {
  value: ComparisonValueType;
}

const ComparisonValue: React.SFC<ComparisonValueProps> = ({ value }) => {
  const isDesktop = typeof window !== 'undefined' && window.innerWidth > 768;
  const showRemark = value.remark && (value.remark.length < 5 || isDesktop);
  const icon = value.supported ? (
    <img src={checkIcon} alt="Supported" />
  ) : (
    <img src={xIcon} alt="Unsupported" />
  );
  const content = showRemark ? value.remark : icon;

  return (
    <div className={value.supported ? styles.yes : styles.no}>{content}</div>
  );
};

const ComparisonTable: React.SFC<ComparisonTableProps> = ({
  competitor: { name, icon, fullLogo },
  comparisons,
  registerSource,
}) => (
  <PageSection bordered>
    <Spacing vertical={11}>
      <h3 className={styles.title}>Switching from {name}?</h3>
      <table className={styles.table}>
        <thead>
          <tr>
            <th />
            <th>
              <img src={slabLogo} alt="Slab" />
            </th>
            <th>
              <img className={styles.icon} src={icon} alt={name} />
              <img className={styles.fullLogo} src={fullLogo} alt={name} />
            </th>
          </tr>
        </thead>
        <tbody>
          {comparisons.map(({ title, description, slab, competitor }) => (
            <tr key={title}>
              <th className={styles.featureNameCell}>
                {title}
                <div className={styles.description}>{description}</div>
              </th>
              <td>
                <ComparisonValue value={slab} />
              </td>
              <td>
                <ComparisonValue value={competitor} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Spacing top={12}>
        <RegisterInsert
          text={`Import from ${name}`}
          description="We'll do the heavy lifting"
          source={registerSource}
          narrow
        />
      </Spacing>
    </Spacing>
  </PageSection>
);

export default ComparisonTable;
