import React from 'react';
import FeatureShowcase from '../../components/home/FeatureShowcase';
import confluenceIcon from '../../images/alternative-to/confluenceIcon.svg';
import confluenceLogo from '../../images/alternative-to/confluenceLogo.svg';
import collaborateIcon from '../../images/feature-icons/collaborate-icon.svg';
import integrateIcon from '../../images/feature-icons/integrate.svg';
import organizationIcon from '../../images/feature-icons/organize-icon.svg';
import retrieveIcon from '../../images/feature-icons/retrieve-icon.svg';
import collaborateIllustration from '../../images/home/features/collaborate.svg';
import integrateIllustration from '../../images/home/features/integrate.svg';
import retrieveIllustration from '../../images/home/features/retrieve.svg';
import verifyIllustration from '../../images/home/features/verify.svg';
import ComparisonTemplate from '../../templates/ComparisonTemplate';

const Page: React.SFC = (props) => (
  <ComparisonTemplate
    competitor={{
      name: 'Confluence',
      icon: confluenceIcon,
      fullLogo: confluenceLogo,
    }}
    metaTitle="Alternative to Confluence Knowledge Base - Slab"
    metaDescription="With Slab, your team won't be confused on how to find what they're looking for contribute to team knowledge. Easily integrate outside Atlassian's stack."
    header="Why go with Slab vs Confluence?"
    subheader="Slab's features, ease of use, and integrations make it the #1 Confluence alternative for team knowledge sharing."
    features={
      <>
        <FeatureShowcase
          icon={collaborateIcon}
          title="Intuitive"
          headline="Easy to use for any team"
          description="Easily create, organize, and discover knowledge for your entire organization. Slab is approachable for non-technical teams yet delightful for tech-savvy teams."
          illustration={collaborateIllustration}
          remarkLogo={confluenceIcon}
          remark="Confluence is so hard to use that there's an entire third-party service industry dedicated to setup and maintenance."
        />
        <FeatureShowcase
          icon={organizationIcon}
          title="Verification"
          headline="Trust your team knowledge"
          description="Verification ensures team knowledge is regularly reviewed, updated, and verified by its designated maintainer, so teammates can trust what they find."
          illustration={verifyIllustration}
          reversed
          remarkLogo={confluenceIcon}
          remark="In Confluence, teammates are left guessing whether content is stale or outdated."
        />
        <FeatureShowcase
          icon={retrieveIcon}
          title="Search"
          headline="Not yet another place to search"
          description="With Unified Search, we'll pull answers from your Slab content and all of your integrated tools, bringing you the most relevant results — all in one place."
          illustration={retrieveIllustration}
          remarkLogo={confluenceIcon}
          remark="Confluence lacks functionality to search integrations' contents."
        />
        <FeatureShowcase
          icon={integrateIcon}
          title="Integrate"
          headline="Integrate with tools you choose"
          description="Slab is a best-in-class product that isn't trying to cross-sell you. That's why it offers first-class integrations with everything else, including your spreadsheets, slide decks, flowcharts, task managers, and much more."
          illustration={integrateIllustration}
          reversed
          remarkLogo={confluenceIcon}
          remark="Outside of Atlassian's stack, integrations are second-class — or worse, built and maintained by third parties."
        />
      </>
    }
    comparisons={[
      {
        title: 'Unlimited posts',
        description: 'No limit to the quanity or length.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Version History',
        description: 'Full history of changes on posts.',
        slab: { supported: true },
        competitor: { supported: true },
      },
      {
        title: 'Unified Search',
        description: 'Search across native content and integrations.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Verification',
        description: 'Avoid stale and outdated knowledge.',
        slab: { supported: true },
        competitor: { supported: false },
      },
      {
        title: 'Analytics',
        description:
          'Insights into team usage, contribution, and engagement teamwide.',
        slab: { supported: true },
        competitor: { supported: false, remark: 'Enterprise only' },
      },
      {
        title: 'Single Sign-On',
        description: 'Security is not a paid privilege on Slab.',
        slab: { supported: true },
        competitor: { supported: false, remark: 'Paid add-on' },
      },
    ]}
    {...props}
  />
);

export default Page;
